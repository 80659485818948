import { useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import classNames from "classnames";

import { setDirection, setScreen, setInputInterest } from "../../store/greet";
import GLOBALS from "../../helpers/globals";
import { swipeConfidenceThreshold, swipePower, screenVariants } from "../../helpers/utils";

import AppButtonRounded from "../Buttons/AppButtonRounded";
import AppButtonLink from "../Buttons/AppButtonLink";
import StepsBullet from "../Navigation/StepsBullet";

const ScreenStepInterest = () => {
  const inputRef = useRef(null);
  const { direction, interest } = useSelector((state) => state.greet);
  const dispatch = useDispatch();
  const showPrevScreen = () => {
    if (direction !== GLOBALS.APP_SCREEN_NAV_PREV) {
      dispatch(setDirection(GLOBALS.APP_SCREEN_NAV_PREV));
    }
    dispatch(setScreen(GLOBALS.APP_SCREEN_STEP_THREE));
  };
  const showNextScreen = () => {
    if (direction !== GLOBALS.APP_SCREEN_NAV_NEXT) {
      dispatch(setDirection(GLOBALS.APP_SCREEN_NAV_NEXT));
    }
    dispatch(setScreen(GLOBALS.APP_SCREEN_STEP_FIVE));
  };
  return (
    <motion.div
      className="absolute inset-0 csa-app-step-interest"
      custom={direction}
      variants={screenVariants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{ duration: GLOBALS.APP_SCREEN_NAV_DURATION }}
      drag="y"
      dragConstraints={{ top: 0, bottom: 0 }}
      dragMomentum={false}
      dragElastic={0}
      onDragEnd={(e, { offset, velocity }) => {
        const swipe = swipePower(offset.y, velocity.y);
        if (swipe < -swipeConfidenceThreshold) {
          if (interest.length > 0) {
            showNextScreen();
          } else {
            inputRef.current.focus();
          }
        } else if (swipe > swipeConfidenceThreshold) {
          showPrevScreen();
        }
      }}
    >
      <div className="absolute inset-0 csa-app-step-interest-inner">
        <div className="absolute inset-0 csa-app-step-interest-bg"></div>
        <div
          className="absolute top-0 left-0 right-0 w-full"
          style={{ height: "75%" }}
        >
          <div className="relative w-full h-full flex flex-wrap items-center text-black">
            <div className="relative w-full pt-60 pb-20">
              <div className="relative px-20">
                <h2>What’s a fun interest you both share?</h2>
              </div>
              <div className="px-20">
                <InputInterest
                  text={interest && interest !== "" ? interest : ""}
                  ref={inputRef}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute bottom-0 left-0 right-0 w-full"
          style={{ height: "25%" }}
        >
          <div className="absolute inset-0 csa-app-step-interest-nav"></div>
          <div className="relative w-full h-full flex flex-wrap items-center justify-center">
            <div className="relative w-full">
              <div className="relative px-40 pt-20">
                <StepsBullet current={4} total={5} />
              </div>
              <div className="relative px-40 py-20">
                <div className="flex flex-wrap align-center justify-between">
                  <AppButtonLink text="<< PREV" onClick={showPrevScreen} />
                  <AppButtonRounded
                    text="NEXT"
                    disabled={interest.length === 0}
                    {...(interest.length > 0
                      ? { onClick: showNextScreen }
                      : null)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div >
  );
};

const InputInterest = forwardRef(({ text }, ref) => {
  const dispatch = useDispatch();
  const [interest, setInterest] = useState(text);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const handleUserInput = (e) => {
    const value = e.target.value;
    setInterest(value);
    if (value && value.trim().length >= 3) {
      dispatch(setInputInterest(value.trim()));
      setIsSuccess(true);
      setIsError(false);
      setErrorMsg("");
    } else {
      setIsSuccess(false);
      setIsError(true);
      setErrorMsg(value.trim().length > 0 ? "It's too short" : "Don't leave it blank");
    }
  };
  return (
    <div className="field-group">
      <div
        className={classNames(
          "relative field-item",
          { error: isError },
          { success: isSuccess }
        )}
      >
        <input
          id="field-interest"
          name="field-interest"
          className="text-field"
          type="text"
          placeholder="e.g. Gaming / Ice-cream / Fishing"
          autoComplete="off"
          value={interest}
          onFocus={(e) => handleUserInput(e)}
          onBlur={(e) => handleUserInput(e)}
          onChange={(e) => handleUserInput(e)}
          ref={ref}
        />
      </div>
      <div className="text-xs text-bold text-center text-error error-msg">{errorMsg}</div>
    </div>
  );
});

export default ScreenStepInterest;
