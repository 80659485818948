import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import greet from './greet';

const rootReducer = combineReducers({
    greet,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;