import { useEffect, useState, memo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AnimatePresence, useCycle } from "framer-motion";

import { setSiteCode, getGeoInfo } from "./store/greet";
import GLOBALS from "./helpers/globals";

import AppLayout from "./components/Layout/AppLayout";
import ScreenWelcome from "./components/Screens/Welcome";
import ScreenStepRelationship from "./components/Screens/StepRelationship";
import ScreenStepName from "./components/Screens/StepName";
import ScreenStepInteracted from "./components/Screens/StepInteracted";
import ScreenStepInterest from "./components/Screens/StepInterest";
import ScreenStepTone from "./components/Screens/StepTone";
import ScreenGreeting from "./components/Screens/Greeting";
import MyRewards from "./components/Overlay/Rewards/MyRewards";
import NavRewardsToggle from "./components/Overlay/NavRewardsToggle";

const App = () => {
  const { siteCode, geoData, screen, direction } = useSelector((state) => state.greet);
  const [isOpen, toggleOpen] = useCycle(false, true);
  const dispatch = useDispatch();
  useEffect(() => {
    const setupSiteCode = async () => {
      if (!geoData) {
        //  console.log("setting up geo")
        await dispatch(getGeoInfo());
      }
      if (!siteCode && geoData && geoData.ip) {
        //  console.log("setting up sitecode")
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let site = params.get('site');
        if (site && ['ae', 'pk', 'ps', 'jo', 'lb'].includes(site)) {
          //  console.log('from params');
          dispatch(setSiteCode(site));
        } else {
          if (geoData && geoData.ip) {
            //  console.log(geoData)
            let ctyCode = geoData.location.country.code;
            if (ctyCode && ['ae', 'pk', 'ps', 'jo', 'lb'].includes(ctyCode.toLowerCase())) {
              //  console.log('from geo');
              dispatch(setSiteCode(ctyCode.toLowerCase()));
            } else if (ctyCode && ctyCode !== '') {
              //  console.log('from geo but not participating country');
              dispatch(setSiteCode(ctyCode.toLowerCase()));
            } else {
              //  console.log('not from geo');
              dispatch(setSiteCode('ae'));
            }
          } else {
            //  console.log('not from params & geo');
            dispatch(setSiteCode('ae'));
          }
        }
      }
    }
    setupSiteCode();
  }, [geoData])
  return (
    <AppLayout>
      <AnimatePresence custom={direction}>
        {screen && screen === GLOBALS.APP_SCREEN_WELCOME && (
          <ScreenWelcome key={GLOBALS.APP_SCREEN_WELCOME} />
        )}
        {screen && screen === GLOBALS.APP_SCREEN_STEP_ONE && (
          <ScreenStepRelationship key={GLOBALS.APP_SCREEN_STEP_ONE} />
        )}
        {screen && screen === GLOBALS.APP_SCREEN_STEP_TWO && (
          <ScreenStepName key={GLOBALS.APP_SCREEN_STEP_TWO} />
        )}
        {screen && screen === GLOBALS.APP_SCREEN_STEP_THREE && (
          <ScreenStepInteracted key={GLOBALS.APP_SCREEN_STEP_THREE} />
        )}
        {screen && screen === GLOBALS.APP_SCREEN_STEP_FOUR && (
          <ScreenStepInterest key={GLOBALS.APP_SCREEN_STEP_FOUR} />
        )}
        {screen && screen === GLOBALS.APP_SCREEN_STEP_FIVE && (
          <ScreenStepTone key={GLOBALS.APP_SCREEN_STEP_FIVE} />
        )}
        {screen && screen === GLOBALS.APP_SCREEN_GREET && (
          <ScreenGreeting key={GLOBALS.APP_SCREEN_GREET} />
        )}
        {
          isOpen && <MyRewards key="my-rewards" isOpen={isOpen} toggle={() => toggleOpen()} />
        }
        {(siteCode === 'ae' || siteCode === 'pk') &&
          <NavRewardsToggle key="nav-menu" screen={screen} isOpen={isOpen} toggle={() => toggleOpen()} />
        }

      </AnimatePresence>
    </AppLayout>
  );
};

export default App;
