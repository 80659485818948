import axios from "axios";

import GLOBALS from "../helpers/globals";

export const fetchGreetingAPI = async (promptText) => {
  try {
    const { data, error } = await axios.post(
      GLOBALS.APP_API_OPENAI_ENDPOINT, {
      model: "gpt-3.5-turbo",
      max_tokens: 100,
      n: 1,
      temperature: 0.2,
      messages: [{ "role": "user", "content": promptText }]
    },
      {
        headers: {
          "Authorization": `Bearer ${process.env.REACT_APP_API_OPENAI_KEY}`,
          "Content-Type": "application/json"
        }
      });
    if (data) {
      return data
    }
    console.log("fetchGreetingAPI error: ", error);
    return null;
  } catch (exception) {
    console.log("fetchGreetingAPI exception: ", exception)
  }
}

export const sendGreetingInfoAPI = async (userInputs, greetResponse, visitor) => {
  try {
    const { data, error } = await axios.post(
      GLOBALS.APP_API_CDP_ENDPOINT, {
      userInputs,
      greetResponse,
      visitor
    },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
    if (data) {
      return data;
    }
    console.log("sendGreetingInfoAPI error: ", error);
    return null;
  } catch (exception) {
    console.log("sendGreetingInfoAPI exception: ", exception)
  }
}