import { createSlice } from "@reduxjs/toolkit";
import { useCookies } from 'react-cookie';
import { voucherList } from "../components/Overlay/Rewards/vouchers";

//  import AES from 'crypto-js/aes';
//  import Utf8 from 'crypto-js/enc-utf8';

import { fetchGoeLocationAPI } from "../dao/geolocation";
import { fetchGreetingAPI, sendGreetingInfoAPI } from "../dao/greet";
import GLOBALS from "../helpers/globals";
//  import { findAndUpdateVouchers } from "../helpers/utils";

const toneTexts = [
  "more and more serious",
  "serious",
  "casual",
  "funny",
  "very funny"
];

const greetBgClasses = [
  "bg-green",
  "bg-yellow-dark",
  "bg-violet",
  "bg-teal",
  "bg-yellow",
  "bg-pink",
  "bg-yellow-thick",
  "bg-blue",
]

const slice = createSlice({
  name: "app",
  initialState: {
    siteCode: '',
    screen: GLOBALS.APP_SCREEN_WELCOME,
    direction: "next",
    relationship: "",
    name: "",
    interacted: "",
    interest: "",
    tone: 3,
    greetText: "",
    greetLength: 0,
    greetBg: "bg-green",
    isGenerating: false,
    isError: false,
    errorMsg: "",
    vouchersWon: 0,
    shareOverlayShown: false,
    geoData: null
  },
  reducers: {
    setAppScreenView: (state, action) => {
      state.screen = action.payload;
      state.direction = action.payload;
    },
    setScreen: (state, action) => {
      state.screen = action.payload;
    },
    setDirection: (state, action) => {
      state.direction = action.payload;
    },
    setInputRelationship: (state, action) => {
      state.relationship = action.payload;
    },
    setInputName: (state, action) => {
      state.name = action.payload;
    },
    setInputInteracted: (state, action) => {
      state.interacted = action.payload;
    },
    setInputInterest: (state, action) => {
      state.interest = action.payload;
    },
    setInputTone: (state, action) => {
      state.tone = action.payload;
    },
    setGreetText: (state, action) => {
      const { text, tokens, bg } = action.payload
      state.greetText = text;
      state.greetLength = tokens;
      state.greetBg = bg;
      state.isError = false;
      state.errorMsg = "";
      state.shareOverlayShown = false;
      state.greetingDownloaded = false;
    },
    setIsGenerating: (state, action) => {
      state.isGenerating = action.payload;
      state.shareOverlayShown = false;
      state.greetingDownloaded = false;
    },
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload;
      state.greetLength = 0;
      state.greetBg = "bg-green";
      state.isError = true;
    },
    setVouchersWon: (state, action) => {
      state.vouchersWon = action.payload;
    },
    setTryAnother: (state, action) => {
      state.isGenerating = false;
      state.greetText = "";
      state.isError = false;
      state.errorMsg = "";
      state.shareOverlayShown = false;
      state.greetingDownloaded = false;
    },
    showGreetingShare: (state, action) => {
      state.shareOverlayShown = true;
    },
    hideGreetingShare: (state, action) => {
      state.shareOverlayShown = false;
    },
    setGreetingDownloaded: (state, action) => {
      state.greetingDownloaded = true;
    },
    resetInputs: (state, action) => {
      state.relationship = "";
      state.name = "";
      state.interacted = "";
      state.interest = "";
      state.tone = 3;
      state.greetText = "";
      state.greetLength = 0;
      state.greetBg = "bg-green";
      state.isGenerating = false;
      state.isError = false;
      state.errorMsg = "";
      state.shareOverlayShown = false;
      state.greetingDownloaded = false;
    },
    setSiteCode: (state, action) => {
      state.siteCode = action.payload;
    },
    setGeoData: (state, action) => {
      state.geoData = action.payload;
    },
  },
});

export default slice.reducer;
export const {
  setScreen,
  setDirection,
  setInputRelationship,
  setInputName,
  setInputInteracted,
  setInputInterest,
  setInputTone,
  setGreetText,
  setIsGenerating,
  setErrorMsg,
  setTryAnother,
  setVouchersWon,
  showGreetingShare,
  hideGreetingShare,
  setGreetingDownloaded,
  resetInputs,
  setSiteCode,
  setGeoData
} = slice.actions;

export const generateGreeting = ({ cookies, setCookie, isRetry }) => async (dispatch, getState) => {
  try {
    dispatch(setIsGenerating(true));
    const { siteCode, geoData, relationship, name, interacted, interest, tone, vouchersWon } = getState().greet;
    const prompt = `Write me one Eid Greeting message in two or three sentences with a ${toneTexts[tone - 1]} way / tone to my ${relationship}, whom I did not meet since ${interacted} and we both have common interest in ${interest}. Don't write any offensive sexual content or bad words. Don't start the message with Hi, Hey, Dear.`;
    /*
    const prompt = `Write me one Eid Greeting message in two or three sentences with a ${toneTexts[tone - 1]} way / tone to my ${relationship}, whom I did not meet since ${interacted} and we both have common interest in ${interest}. Don't write any offensive sexual content or bad words.`;
    */
    const greetData = await fetchGreetingAPI(prompt);
    dispatch(setIsGenerating(false));
    if (greetData && greetData.choices && greetData.choices.length > 0) {
      //  dispatch(setGreetText(greetData.choices[0].text));
      const randomNum = Math.floor(Math.random() * greetBgClasses.length);
      dispatch(setGreetText({ text: greetData.choices[0].message.content, tokens: greetData.usage.completion_tokens, bg: greetBgClasses[randomNum] }));
      sendGreetingInfo({ siteCode, relationship, name, interacted, interest, tone }, greetData, geoData);
      if (!isRetry) {
        if (vouchersWon < voucherList[siteCode].length) {
          dispatch(setVouchersWon(vouchersWon + 1));
          findAndUpdateVouchers(setCookie, vouchersWon + 1);
        }
      }
    } else {
      dispatch(setErrorMsg("Oops! \n\nWe are experiencing some unexpected issues. \n\nPlease Try Again"))
    }
  } catch (e) {
    console.log("generateGreeting: ", e);
    dispatch(setErrorMsg("Oops! \n\nWe are experiencing some unexpected issues. \n\nPlease Try Again"))
  }
}

export const sendGreetingInfo = (userInputs, greetResponse, geoData) => {
  try {
    if (geoData && geoData.ip) {
      sendGreetingInfoAPI(userInputs, greetResponse, geoData)
    }
  } catch (e) {
    console.log("sendGreetingInfo: ", e);
  }
}

export const getGeoInfo = () => async (dispatch, getState) => {
  try {
    const geoData = await fetchGoeLocationAPI();
    if (geoData && geoData.ip) {
      dispatch(setGeoData(geoData));
    }
  } catch (e) {
    console.log("getGeoInfo: ", e);
  }
}



const findAndUpdateVouchers = (setCookie, vouchersCount) => {
  let expiryTime = new Date();
  expiryTime.setDate(expiryTime.getDate() + 60);
  setCookie('EidVouchersCount', vouchersCount, { path: '/', expires: expiryTime });
}