const AppLayout = ({ children }) => (
  <div className="csa-page-inner"
    //  style={{ maxWidth: `${window.innerHeight * 0.486486486}px` }}
  >
    <div className="csa-app-view">
      <div className="absolute inset-0 w-full h-full">
        {children}
      </div>
    </div>
  </div>
)

export default AppLayout;