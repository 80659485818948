import classNames from "classnames";

const AppButtonContained = ({ text, disabled, onClick }) => {
  return (
    <button
      className={classNames("cta-contained-black", { disabled: disabled })}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default AppButtonContained;