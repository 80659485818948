import classNames  from "classnames";

const StepsBullet = ({ current, total }) => {
  return (
    <div className="flex flex-nowrap justify-evenly">
      <div className="px-10 text-left text-xs">
        Step {current} of {total}
      </div>
      <div className="px-10">
        <div className="flex">
          {[...Array(total).keys()].map((item, i) => (
            <StepsBulletItem
              key={i}
              isFilled={item < (current-1)}
              isCurrent={item === (current-1)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const StepsBulletItem = ({ isFilled, isCurrent }) => {
  return (
    <div className="bullet">
      <button className={classNames('dot', { active: isFilled, current: isCurrent })}></button>
    </div>
  );
};

export default StepsBullet;
