import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import store from './store';

import App from './App';
import reportWebVitals from './reportWebVitals';

// Fonts
import './assets/fonts/SamsungOne-400.eot';
import './assets/fonts/SamsungOne-400.ttf';
import './assets/fonts/SamsungOne-400.woff';
import './assets/fonts/SamsungOne-400.woff2';
import './assets/fonts/SamsungOne-700.eot';
import './assets/fonts/SamsungOne-700.ttf';
import './assets/fonts/SamsungOne-700.woff';
import './assets/fonts/SamsungOne-700.woff2';
import './assets/fonts/SamsungSharpSansBd.eot';
import './assets/fonts/SamsungSharpSansBd.woff';
import './assets/fonts/SamsungSharpSansBd.woff2';

// CSS
import './assets/css/base.css';
import './assets/css/layout.css';
import './assets/css/component.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
