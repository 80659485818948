import axios from "axios";
import GLOBALS from "../helpers/globals";
export const fetchGoeLocationAPI = async () => {
  try {    
    const { data, error } = await axios.get(GLOBALS.APP_API_GEOLOCATION_ENDPOINT, {
      params: {
        key: process.env.REACT_APP_API_GEOLOCATION_KEY
      }
    })
    if (data) {
      return data;
    } else {
      console.log("fetchGoeLocationAPI: ", error);
    }
  } catch (exception) {
    console.log("fetchGoeLocationAPI: ", exception);
    return null;
  }
}