import { useDispatch, useSelector } from "react-redux";
import { useCookies } from 'react-cookie';
import { motion } from "framer-motion";

import GLOBALS from "../../../helpers/globals";
import { overlayVariants, overlayListVariants, overlayListItemVariants } from "../../../helpers/utils";

import AppButtonRounded from "../../Buttons/AppButtonRounded";
import AppButtonContained from "../../Buttons/AppButtonContained";
import MyRewardItem from "../../Overlay/Rewards/MyRewardItem";

const itemIds = [0, 1, 2, 3, 4, 5, 6, 7];

const MyRewards = ({ isOpen, toggle }) => {
  const [cookies] = useCookies([process.env.REACT_APP_AES_ENCRYPT_PASSPHRASE]);
  const { siteCode } = useSelector(state => state.greet)
  return (
    <motion.div
      variants={overlayVariants}
      custom={740}
      //  initial={false}
      //  animate={isOpen ? "open" : "closed"}
      initial="enter"
      animate="open"
      exit="closed"
      transition={{ duration: GLOBALS.APP_SCREEN_NAV_DURATION }}
      className="absolute inset-0 csa-app-rewards"
    >
      <div className="absolute inset-0 csa-app-rewards-inner">
        <div className="absolute inset-0 csa-app-rewards-bg"></div>
        <div
          className="absolute top-0 left-0 right-0 w-full"
          style={{ height: "14%" }}
        >
          <div className="relative w-full h-full flex flex-wrap justify-center items-end">
            <div className="px-20 pt-10 pb-20 text-center">
              <p className="text-sans text-xl">My Rewards</p>
            </div>
          </div>
        </div>
        <div
          className="absolute top-0 left-0 right-0 w-full csa-app-rewards-main"
          style={{ height: "72%", top: "14%" }}
        >
          <div
            className="absolute top-0 left-0 right-0 w-full csa-app-rewards-info"
            style={{ height: "28%" }}
          >
            <div className="relative w-full h-full flex flex-wrap justify-center items-center">
              <div className="relative px-20 pt-10 pb-10">
                <div className="text-center">
                  {
                    siteCode && siteCode === 'pk' &&
                    <p className="text-sans text-xs">Unlock different offers by generating more Eid greetings. Use the code <span className="csa-code-spl">BARIEID23</span> during checkout at samsung.com or share it with someone you know.</p>
                  }
                  {
                    siteCode && siteCode === 'ae' &&
                    <p className="text-sans text-xs">Unlock different offers by generating more Eid greetings. Use the code <span className="csa-code-spl">SAM01</span> during checkout at samsung.com or share it with someone you know.</p>
                  }
                </div>
              </div>
            </div>
          </div>
          <div
            className="absolute top-0 left-0 right-0 w-full csa-app-rewards-offers"
            style={{ height: "72%", top: "28%" }}
          >
            <div className="relative w-full h-full flex flex-wrap items-center justify-center">
              <div className="relative w-full">
                <div className="relative px-20 pt-30 pb-40">
                  {
                    cookies && cookies.EidVouchersCount > 0 && <MyRewardsOffer count={parseInt(cookies.EidVouchersCount)} siteCode={siteCode} />
                  }
                  {
                    !cookies || !cookies.EidVouchersCount && <MyRewardsNoOffer toggle={toggle} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute bottom-0 left-0 right-0 w-full"
          style={{ height: "14%" }}
        >
          <MyRewardsFooter toggle={toggle} siteCode={siteCode} />
        </div>
      </div>
    </motion.div>
  )
}

const MyRewardsOffer = ({ count, siteCode }) => {
  return (
    <motion.div className="csa-app-rewards-list"
      variants={overlayListVariants}
    >
      {[...Array(count).keys()].map(i => (
        <MyRewardItem i={i} key={i} siteCode={siteCode} />
      ))}
    </motion.div>
  )
}

const MyRewardsFooter = ({ toggle, siteCode }) => {
  return (
    <div className="relative w-full h-full flex flex-wrap items-center justify-center">

      <div className="px-20">
        <div className="py-10">
          <div className="text-center">
            {siteCode && siteCode === 'ae' &&
              <a className="csa-cta-tnc" href="#" target="_blank">*T&Cs apply</a>
            }
            {siteCode && siteCode === 'pk' &&
              <a className="csa-cta-tnc" href="#" target="_blank">*T&Cs apply</a>
            }
            {siteCode && siteCode !== 'ae' && siteCode !== 'pk' &&
              <a className="csa-cta-tnc" href="#" target="_blank">*T&Cs apply</a>
            }
          </div>
        </div>
        <div className="pb-10">
          <div className="text-center">
            <AppButtonRounded text="Close" onClick={toggle} />
          </div>
        </div>
      </div>
    </div>
  )
}

const MyRewardsNoOffer = ({ toggle }) => {
  const style = { border: `2px solid #FF008C` };
  return (
    <motion.div className="px-10 csa-app-rewards-list"
      variants={overlayListVariants}
    >
      <motion.div
        variants={overlayListItemVariants}
        initial="enter"
        animate="open"
        exit="closed"
        className="csa-app-rewards-nooffer"
      >
        <div className="text-center">
          <h4>Get rewarded now!</h4>
          <div className="py-10">
            <p>The more greetings you generate, the more rewards you’ll unlock.</p>
          </div>
          <AppButtonContained text="Start now" onClick={toggle} />
        </div>
      </motion.div>
    </motion.div>
  )
}

export default MyRewards;