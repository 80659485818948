const GLOBALS = {
  APP_SCREEN_WELCOME: "welcome",
  APP_SCREEN_STEP_ONE: "relationship",
  APP_SCREEN_STEP_TWO: "name",
  APP_SCREEN_STEP_THREE: "interact",
  APP_SCREEN_STEP_FOUR: "interest",
  APP_SCREEN_STEP_FIVE: "tone",
  APP_SCREEN_GREET: "greet",
  APP_SCREEN_NAV_PREV: "prev",
  APP_SCREEN_NAV_NEXT: "next",
  APP_SCREEN_NAV_DURATION: 0.25,
  APP_SCREEN_NAV_STIFFNESS: 300,
  APP_SCREEN_NAV_DAMPING: 30,
  APP_API_OPENAI_ENDPOINT: "https://api.openai.com/v1/chat/completions",
  APP_API_CDP_ENDPOINT: "https://imp.cheilsmartapps.com/greets/add",
  APP_API_GEOLOCATION_ENDPOINT: "https://api.ipregistry.co/",
  APP_REWARDS_SHOP_URL: "https://www.samsung.com/ae/offer/",
  APP_GREET_LENGTH_LIMIT_MAX: 65
};

export default GLOBALS;