import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { overlayListItemVariants, overlayListItemShareVariants } from '../../../helpers/utils';
import { voucherList, voucherColorCodes } from "./vouchers"

import iconSocialWhatsapp from "../../../assets/images/icon-social-whatsapp.png";
import iconSocialFacebook from "../../../assets/images/icon-social-facebook.png";
import iconSocialEmail from "../../../assets/images/icon-social-email.png";
import iconSocialPinterest from "../../../assets/images/icon-social-pinterest.png";
import iconSocialTwitter from "../../../assets/images/icon-social-twitter.png";

const MyRewardItem = ({ siteCode, i }) => {
  const [clicked, setClicked] = useState(false);
  const voucherItem = voucherList[siteCode][i];
  const style = { color: `${voucherColorCodes[i]}` };

  const handleItemClick = () => {
    setClicked(!clicked)
  }

  const handleWhatsappShare = (e) => {
    let message = `${voucherItem.description} ${voucherItem.url} Check out https://regreet.ai and have fun generating your own Eid Greeting to unlock more rewards.`;
    window.open(`whatsapp://send?text=${message}`, "_blank");
  }

  const handleFacebookShare = (e) => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${voucherItem.url}`, "_blank");
  }

  const handleTwitterShare = (e) => {
    //  let message = `Use ${voucherItem.code} to ${voucherItem.title}. ${voucherItem.url} \nCheck out https://regreet.ai (actual URL to be updated accordingly) and have fun generating your own Eid Greeting to unlock more rewards.`;
    let message = `${voucherItem.description} Check out regreet.ai and have fun generating your own Eid Greeting to unlock more rewards.`;
    window.open(`https://twitter.com/intent/tweet?text=${message}&url=${voucherItem.url}`, "_blank");
  }

  return (
    <>
      {voucherItem &&
        <motion.div
          variants={overlayListItemVariants}
          initial="enter"
          animate="open"
          exit="closed"
          //  whileHover={{ scale: 1.05 }}
          //  whileTap={{ scale: 0.95 }}
          className="csa-app-rewards-item"
        //  style={style}
        >
          <div className="csa-app-rewards-item-inner" onClick={handleItemClick}>
            <div className="csa-app-rewards-item-box">
              <div className="csa-app-rewards-visual">
                <div className="csa-app-rewards-visual-inner">
                  <img src={voucherItem.thbUrl} alt={voucherItem.title} />
                </div>
              </div>
              <div className="csa-app-rewards-content">
                <div className="csa-app-rewards-content-inner">
                  {/* (siteCode === 'sa' || siteCode === 'ma') &&
                    <div className="csa-app-rewards-content-code" style={style}>
                      {voucherItem.code}
                    </div>
                  */}
                  <div className="csa-app-rewards-content-text">
                    {voucherItem.title}
                  </div>
                  <div>
                    <a className="csa-app-rewards-content-cta" href={voucherItem.shopUrl} target="_blank">Shop now</a>
                  </div>
                </div>
              </div>
            </div>
            <AnimatePresence>
              {clicked &&
                <MyRewardItemShare
                  doWhatsappShare={handleWhatsappShare}
                  doFacebookShare={handleFacebookShare}
                  doTwitterShare={handleTwitterShare}
                />
              }
            </AnimatePresence>
          </div>
        </motion.div>
      }
    </>
  );
};

const MyRewardItemShare = ({ doWhatsappShare, doFacebookShare, doTwitterShare }) => {
  return (
    <motion.div
      variants={overlayListItemShareVariants}
      initial="enter"
      animate="open"
      exit="closed"
      className="csa-app-rewards-item-actions"
    >
      <div className="csa-app-rewards-item-actions-inner">
        <div className="csa-app-rewards-item-actions-box">
          <div className="text-xs csa-app-rewards-item-actions-label">
            Share as a gift
          </div>
          <button className="cta-social-icon-sm" onClick={doWhatsappShare}>
            <img src={iconSocialWhatsapp} alt="Whatsapp">
            </img>
          </button>
          <button className="cta-social-icon-sm" onClick={doFacebookShare}>
            <img src={iconSocialFacebook} alt="Facebook">
            </img>
          </button>
          <button className="cta-social-icon-sm" onClick={doTwitterShare}>
            <img src={iconSocialTwitter} alt="Twitter">
            </img>
          </button>
          <button className="cta-social-icon-sm">
            <img src={iconSocialEmail} alt="Email">
            </img>
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default MyRewardItem;