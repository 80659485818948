import { useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from 'react-cookie';
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";

import { setDirection, setScreen, setInputTone, generateGreeting } from "../../store/greet";
import GLOBALS from "../../helpers/globals";
import { swipeConfidenceThreshold, swipePower, screenVariants, overlayGreetingShareVariants } from "../../helpers/utils";

import AppButtonLink from "../Buttons/AppButtonLink";
import StepsBullet from "../Navigation/StepsBullet";

import imgEmojiSerious from "../../assets/images/icon-emoji-serious.png";
import imgEmojiCasual from "../../assets/images/icon-emoji-casual.png";
import imgEmojiFunny from "../../assets/images/icon-emoji-funny.png";
import iconClose from "../../assets/images/icon-close.png";
import AppButtonRounded from "../Buttons/AppButtonRounded";

const ScreenStepTone = () => {
  const [cookies, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AES_ENCRYPT_PASSPHRASE]);
  const [disclaimerClicked, setDisclaimerClicked] = useState(false);
  const inputRef = useRef(null);
  const { direction, tone, greetText } = useSelector((state) => state.greet);
  const dispatch = useDispatch();
  const showPrevScreen = () => {
    if (direction !== GLOBALS.APP_SCREEN_NAV_PREV) {
      dispatch(setDirection(GLOBALS.APP_SCREEN_NAV_PREV));
    }
    dispatch(setScreen(GLOBALS.APP_SCREEN_STEP_FOUR));
  };
  const showNextScreen = () => {
    if (direction !== GLOBALS.APP_SCREEN_NAV_NEXT) {
      dispatch(setDirection(GLOBALS.APP_SCREEN_NAV_NEXT));
    }
    dispatch(setScreen(GLOBALS.APP_SCREEN_GREET));
    dispatch(generateGreeting({ cookies, setCookie, isRetry: false }));
  };
  const showDisclaimer = (val) => {
    setDisclaimerClicked(val);
  }
  return (
    <motion.div
      className="absolute inset-0 csa-app-step-tone"
      custom={direction}
      variants={screenVariants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{ duration: GLOBALS.APP_SCREEN_NAV_DURATION }}
      drag="y"
      dragConstraints={{ top: 0, bottom: 0 }}
      dragMomentum={false}
      dragElastic={0}
      onDragEnd={(e, { offset, velocity }) => {
        const swipe = swipePower(offset.y, velocity.y);
        if (swipe < -swipeConfidenceThreshold) {
          if (tone > 0 && tone <= 5) {
            showNextScreen();
          } else {
            inputRef.current.focus();
          }
        } else if (swipe > swipeConfidenceThreshold) {
          showPrevScreen();
        }
      }}
    >
      <div className="absolute inset-0 csa-app-step-tone-inner">
        <div className="absolute inset-0 csa-app-step-tone-bg"></div>
        <div
          className="absolute top-0 left-0 right-0 w-full"
          style={{ height: "75%" }}
        >
          <div className="relative w-full h-full flex flex-wrap items-center text-black">
            <div className="relative w-full pt-60 pb-20">
              <div className="relative px-20">
                <h2>Tell us how serious, casual or funny you want to be</h2>
              </div>
              <div className="px-20 pt-40">
                <InputTone text={tone && tone > 0 && tone <= 5 ? tone : 3} ref={inputRef} />
              </div>
              <div className="px-20">
                <div className="flex flex-wrap items-center justify-between csa-app-step-tone-emojis">
                  <img className="icon" src={imgEmojiSerious} alt="Serious" />
                  <img className="icon" src={imgEmojiCasual} alt="Casual" />
                  <img className="icon" src={imgEmojiFunny} alt="Funny" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute bottom-0 left-0 right-0 w-full"
          style={{ height: "25%" }}
        >
          <div className="absolute inset-0 csa-app-step-tone-nav"></div>
          <div className="relative w-full h-full flex flex-wrap items-center justify-center">
            <div className="relative w-full">
              <div className="relative px-40 pt-20">
                <StepsBullet current={5} total={5} />
              </div>
              <div className="relative px-20 py-20">
                <div className="flex flex-wrap align-center justify-between">
                  <AppButtonLink text="<< PREV" onClick={showPrevScreen} />
                  <AppButtonRounded
                    text="GENERATE GREETING"
                    disabled={tone <= 0 && tone > 5}
                    {...(tone > 0 && tone <= 5 ? { onClick: showNextScreen } : null)}
                  />
                </div>
              </div>
              <div className="relative px-20 pb-10 text-center">
                <a className="csa-cta-disclaimer" onClick={() => showDisclaimer(true)}>*Disclaimer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {disclaimerClicked && <DisclaimerOverlay closeDisclaimer={showDisclaimer} />}
      </AnimatePresence>
    </motion.div>
  );
};

const InputTone = forwardRef(({ text }, ref) => {
  const dispatch = useDispatch();
  const [tone, setTone] = useState(text);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const handleUserInput = (e) => {
    const value = parseInt(e.target.value);
    setTone(value);
    if (value > 0 && value <= 5) {
      dispatch(setInputTone(value));
      setIsSuccess(true);
      setIsError(false);
      setErrorMsg("");
    } else {
      setIsSuccess(false);
      setIsError(true);
      setErrorMsg("It's beyond the range");
    }
  };
  return (
    <div className="field-group">
      <div
        className={classNames(
          "relative field-range-item",
          { error: isError },
          { success: isSuccess }
        )}
      >
        <input id="field-tone"
          name="field-tone"
          type="range"
          min="1" max="5"
          step="1"
          value={tone}
          onChange={(e) => handleUserInput(e)}
        />
      </div>
    </div>
  );
});

const DisclaimerOverlay = ({ closeDisclaimer }) => {
  return (
    <motion.div
      variants={overlayGreetingShareVariants}
      initial="enter"
      animate="open"
      exit="closed"
      className="absolute bottom-0 left-0 w-full csa-app-disclaimer-overlay"
    >
      <div className="relative w-full h-full flex flex-wrap justify-center items-center">
        <div className="px-20 py-10 text-sm">
          *Samsung does not take any legal liability nor control any content generated on this platform
        </div>
      </div>
      <button className="csa-app-disclaimer-overlay-close" onClick={() => closeDisclaimer(false)}>
        <img src={iconClose} alt="Close" />
      </button>
    </motion.div>
  );
}

export default ScreenStepTone;