import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import GLOBALS from "../../helpers/globals";

import IconRewards from "../../assets/images/icon-rewards.png";
import IconRewardsWhite from "../../assets/images/icon-rewards-white.png";
import IconClose from "../../assets/images/icon-close.png";
import { setVouchersWon } from '../../store/greet';
import { useState } from 'react';


const NavRewardsToggle = ({ screen, isOpen, toggle }) => {
  const dispatch = useDispatch();
  const { vouchersWon } = useSelector(state => state.greet);
  const [cookies] = useCookies([process.env.REACT_APP_AES_ENCRYPT_PASSPHRASE]);

  useEffect(() => {
    if (cookies && cookies.EidVouchersCount && parseInt(cookies.EidVouchersCount) > 0) {
      dispatch(setVouchersWon(parseInt(cookies.EidVouchersCount)));
    }
  }, [cookies]);
  return (
    <button className="csa-nav-menu" onClick={toggle}>
      {!isOpen &&
        <>
          <img src={screen === GLOBALS.APP_SCREEN_WELCOME ? IconRewardsWhite : IconRewards} alt="My Rewards" />
          <span className='csa-badge'>{vouchersWon}</span>
        </>
      }
      {isOpen && <img src={IconClose} alt="Close" />}
    </button>
  );
}

export default NavRewardsToggle;