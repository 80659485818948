import { useCookies } from 'react-cookie';

export const swipeConfidenceThreshold = 10000;

export const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export const screenVariants = {
  enter: (direction) => {
    return {
      y: direction === "prev" ? "-100%" : "100%",
    };
  },
  center: {
    y: 0,
  },
  exit: (direction) => {
    return {
      y: direction === "prev" ? "100%" : "-100%",
    };
  },
};

export const overlayVariants = {
  enter: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40,
      restDelta: 2
    }
  },
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    //  originX: 0.1,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.4,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
}

export const overlayListVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.5 }
  },
  closed: {
    transition: { staggerChildren: 0.02, staggerDirection: -1 }
  }
};

export const overlayListItemVariants = {
  enter: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  },
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

export const overlayListItemShareVariants = {
  enter: {
    //  y: 50,
    opacity: 0,
    transition: {
      duration: 0.2
      //  y: { stiffness: 1000 }
    }
  },
  open: {
    //  y: 0,
    opacity: 1,
    transition: {
      duration: 0.2
      //  y: { stiffness: 100, velocity: -1 }
    }
  },
  closed: {
    //  y: 50,
    opacity: 0,
    transition: {
      duration: 0.2
      //  y: { stiffness: 100 }
    }
  }
};

export const overlayGreetingShareVariants = {
  enter: {
    y: "100%",
    opacity: 0.5,
    transition: {
      y: { stiffness: 1000 }
    }
  },
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: "100%",
    opacity: 0.5,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}

export const findAndUpdateVouchers = (cookies, setCookie) => {
  let expiryTime = new Date();
  expiryTime.setDate(expiryTime.getDate() + 60);
  if (cookies && cookies.VouchersCount && parseInt(cookies.VouchersCount) < 19) {
    setCookie('VouchersCount', parseInt(cookies.VouchersCount) + 1, { path: '/', expires: expiryTime });
  } else {
    setCookie('VouchersCount', 1, { path: '/', expires: expiryTime });
  }
}