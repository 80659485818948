import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";

import LogoSamsung from "../../assets/images/samsung-logo-white.png"
import LogoRegreetAI from "../../assets/images/logo-regreet-main.png";

import { setDirection, setScreen } from "../../store/greet";
import GLOBALS from "../../helpers/globals";
import {
  swipeConfidenceThreshold,
  swipePower,
  screenVariants,
} from "../../helpers/utils";

import AppButtonRounded from "../Buttons/AppButtonRounded";
import { useEffect } from "react";

const ScreenWelcome = () => {
  const { siteCode, direction } = useSelector((state) => state.greet);
  const dispatch = useDispatch();
  const showNextScreen = () => {
    if (direction !== GLOBALS.APP_SCREEN_NAV_NEXT) {
      dispatch(setDirection(GLOBALS.APP_SCREEN_NAV_NEXT));
    }
    dispatch(setScreen(GLOBALS.APP_SCREEN_STEP_ONE));
  };
  return (
    <motion.div
      className="absolute inset-0 csa-app-welcome"
      custom={direction}
      variants={screenVariants}
      initial={direction === GLOBALS.APP_SCREEN_NAV_PREV ? "enter" : ""}
      animate="center"
      exit="exit"
      transition={{ duration: GLOBALS.APP_SCREEN_NAV_DURATION }}
      drag="y"
      dragConstraints={{ top: 0, bottom: 0 }}
      dragMomentum={false}
      dragElastic={0}
      onDragEnd={(e, { offset, velocity }) => {
        const swipe = swipePower(offset.y, velocity.y);
        if (swipe < -swipeConfidenceThreshold) {
          showNextScreen();
        } else if (swipe > swipeConfidenceThreshold) {
        }
      }}
    >
      <div className="absolute inset-0 csa-app-welcome-inner">
        <div className="absolute inset-0 csa-app-welcome-bg"></div>
        <div className="absolute csa-app-welcome-logo-samsung">
          <img src={LogoSamsung} alt="Samsung" />
        </div>
        <div
          className="absolute top-0 left-0 right-0 w-full"
          style={{ height: "76%" }}
        >
          <div className="relative w-full h-full flex flex-wrap items-center">
            <div className="relative pt-60 pb-10">
              <div className="relative px-20 pt-10 pb-10 text-white w-logo">
                <img
                  className="block"
                  src={LogoRegreetAI}
                  alt="Eid Regreet AI Logo"
                />
              </div>
              <div className="relative px-20 pt-20 text-white">
                <h1>
                  Connect with <br />
                  those you’ve <br />
                  lost touch with <br />
                  this Eid
                </h1>
              </div>
              <div className="relative px-20 pb-10 text-white">
                <p className="text-xs">
                  Have fun generating a greeting, without the fuss of what to
                  write. </p>
                {
                  siteCode && (siteCode === 'ae' || siteCode === 'pk') &&
                  <p className="text-xs">The more greetings you share, the more rewards you’ll
                    unlock.
                  </p>
                }
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute bottom-0 left-0 right-0 w-full"
          style={{ height: "24%" }}
        >
          <div className="absolute inset-0 csa-app-welcome-nav"></div>
          <div className="relative w-full h-full flex flex-wrap items-center justify-center">
            <div className="relative w-full px-20">
              <div className="relative pt-20">
                <p>Ready to connect smarter?</p>
              </div>
              <div className="relative py-20 text-center">
                <AppButtonRounded text="Let's go!" onClick={showNextScreen} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ScreenWelcome;
