export const voucherList = {
  'ae': [
    {
      code: "SAM01",
      title: "Additional 5% off Galaxy S23 Ultra 1TB",
      description: "Use SAM01 to get additional 5% off Galaxy S23 Ultra 1TB",
      shopUrl: 'https://www.samsung.com/ae/offer/',
      url: "https://www.samsung.com/ae/offer/",
      thbUrl: "https://images.samsung.com/is/image/samsung/p6pim/ae/2302/gallery/ae-galaxy-s23-s918-sm-s918bliwmea-thumb-534838523",
    },
    {
      code: "SAM01",
      title: "29% off Galaxy Watch4 46mm",
      description: "Use SAM01 to get 29% off Galaxy Watch4 46mm",
      shopUrl: 'https://www.samsung.com/ae/offer/',
      url: "https://www.samsung.com/ae/offer/",
      thbUrl: "https://images.samsung.com/is/image/samsung/p6pim/ae/2108/gallery/ae-galaxy-watch4-classic-398768-sm-r890nzkamea-thumb-480975323",
    },
    {
      code: "SAM01",
      title: "45% discount on 43” The Sero",
      description: "Use SAM01 to get 45% discount on 43” The Sero",
      shopUrl: 'https://www.samsung.com/ae/offer/',
      url: "https://www.samsung.com/ae/offer/",
      thbUrl: "https://images.samsung.com/is/image/samsung/p6pim/ae/qa43ls05bauxzn/gallery/ae-the-sero-ls05b-qa43ls05bauxzn-thumb-531977405",
    }, 
    {
      code: "SAM01",
      title: "25% off Twin Cooling Refrigerator",
      description: "Use SAM01 to get 25% off Twin Cooling Refrigerator",
      shopUrl: 'https://www.samsung.com/ae/offer/',
      url: "https://www.samsung.com/ae/offer/",
      thbUrl: "https://images.samsung.com/is/image/samsung/ae-top-mount-freezer-rt72k6357sl-rt50k6357sl-ae-rperspectivesilver-thumb-164335054",
    },
    {
      code: "SAM01",
      title: "50% off 32” Smart Monitor",
      description: "Use SAM01 to get 50% off 32” Smart Monitor",
      shopUrl: 'https://www.samsung.com/ae/offer/',
      url: "https://www.samsung.com/ae/offer/",
      thbUrl: "https://images.samsung.com/is/image/samsung/p6pim/ae/ls32bm500emxue/gallery/ae-smart-m5-27m50b-ls32bm500emxue-thumb-536778557",
    }, 
  ],
  'pk': [
    {
      code: "BARIEID23",
      title: "Additional 13% off Galaxy A04 32GB",
      description: "Use BARIEID23 to get additional 13% off Galaxy A04 32GB",
      shopUrl: 'https://www.samsung.com/pk/offer/',
      url: "https://www.samsung.com/pk/offer/",
      thbUrl: "https://images.samsung.com/is/image/samsung/p6pim/pk/sm-a045fzcdpkd/gallery/pk-galaxy-a04-sm-a045-sm-a045fzcdpkd-thumb-535138670",
    },
    {
      code: "BARIEID23",
      title: "Save Rs 4,000 on Galaxy A04 64GB",
      description: "Use BARIEID23 to save Rs 4,000 on Galaxy A04 64GB",
      shopUrl: 'https://www.samsung.com/pk/offer/',
      url: "https://www.samsung.com/pk/offer/",
      thbUrl: "https://images.samsung.com/is/image/samsung/p6pim/pk/sm-a045fzcdpkd/gallery/pk-galaxy-a04-sm-a045-sm-a045fzcdpkd-thumb-535138670",
    },
    {
      code: "BARIEID23",
      title: "Rs 45,000 off on Side-By-Side Refrigerator",
      description: "Use BARIEID23 to get Rs 45,000 off on Side-By-Side Refrigerator",
      shopUrl: 'https://www.samsung.com/pk/offer/',
      url: "https://www.samsung.com/pk/offer/",
      thbUrl: "https://images.samsung.com/is/image/samsung/p6pim/pk/rs62r5001b4-sg/gallery/pk-ref-sbs-rs5000-321672-rs62r5001b4-sg-536576245?$330_330_JPG$",
    },
    {
      code: "BARIEID23",
      title: "19% off The Freestyle",
      description: "Use BARIEID23 to get 19% off The Freestyle",
      shopUrl: 'https://www.samsung.com/pk/offer/',
      url: "https://www.samsung.com/pk/offer/",
      thbUrl: "https://images.samsung.com/is/image/samsung/p6pim/pk/sp-lsp3blaxmm/gallery/pk-the-freestyle-lsp3b-sp-lsp3blaxmm-thumb-535993529",
    },
    {
      code: "BARIEID23",
      title: "Rs 22,000 off 65” QLED TV Q60B",
      description: "Use BARIEID23 to get Rs 22,000 off 65” QLED TV Q60B",
      shopUrl: 'https://www.samsung.com/pk/offer/',
      url: "https://www.samsung.com/pk/offer/",
      thbUrl: "https://images.samsung.com/is/image/samsung/p6pim/pk/qa65q60bauxmm/gallery/pk-qled-q60b-qa65q60bauxmm-thumb-533350629",
    },
    {
      code: "BARIEID23",
      title: "Additional 10% off 85” QLED TV Q70A",
      description: "Use BARIEID23 to get additional 10% off 85” QLED TV Q70A",
      shopUrl: 'https://www.samsung.com/pk/offer/',
      url: "https://www.samsung.com/pk/offer/",
      thbUrl: "https://images.samsung.com/is/image/samsung/p6pim/pk/qa85q70aauxmm/gallery/pk-qled-q70a-qa85q70aauxmm-thumb-537117395_10129605063312",
    },
  ],
};

export const voucherColorCodes = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF", "#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];