import { useEffect, memo, useRef, forwardRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useCookies } from "react-cookie";
import { AnimatePresence, motion } from "framer-motion";
import * as htmlToImage from "html-to-image";
import classNames from "classnames";
import download from "downloadjs";

import imgRegreetLogo from "../../assets/images/logo-regreet.png";
import imgRegreetConnect from "../../assets/images/regreet-visual.png";
import imgStarsGroup3 from "../../assets/images/stars-group3.png";
import imgStarsGroup2 from "../../assets/images/stars-group2.png";
import iconRetry from "../../assets/images/icon-retry.png";
import iconShare from "../../assets/images/icon-share.png";
import iconClose from "../../assets/images/icon-close.png";
import iconSocialWhatsapp from "../../assets/images/icon-social-whatsapp.png";
import iconSocialFacebook from "../../assets/images/icon-social-facebook.png";
import iconSocialEmail from "../../assets/images/icon-social-email.png";
import iconSocialPinterest from "../../assets/images/icon-social-pinterest.png";
import iconSocialMore from "../../assets/images/icon-social-more.png";

import {
  setDirection,
  setScreen,
  resetInputs,
  generateGreeting,
  setTryAnother,
  showGreetingShare,
  hideGreetingShare,
  setGreetingDownloaded,
} from "../../store/greet";
import GLOBALS from "../../helpers/globals";
import {
  swipeConfidenceThreshold,
  swipePower,
  screenVariants,
  overlayGreetingShareVariants,
} from "../../helpers/utils";

import AppButtonRounded from "../Buttons/AppButtonRounded";
import AppButtonLink from "../Buttons/AppButtonLink";

import GreetingLoader from "./GreetingLoader";


const ScreenGreeting = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    process.env.REACT_APP_AES_ENCRYPT_PASSPHRASE,
  ]);
  const {
    direction,
    name,
    greetText,
    greetLength,
    greetBg,
    isGenerating,
    isError,
    errorMsg,
    shareOverlayShown,
    greetingDownloaded,
  } = useSelector((state) => state.greet);

  const dispatch = useDispatch();

  const grettingPreviewRef = useRef(null);

  const showPrevScreen = () => {
    if (direction !== GLOBALS.APP_SCREEN_NAV_PREV) {
      dispatch(setDirection(GLOBALS.APP_SCREEN_NAV_PREV));
    }
    dispatch(setScreen(GLOBALS.APP_SCREEN_STEP_FIVE));
  };
  const handleStartOver = () => {
    dispatch(resetInputs());
    if (direction !== GLOBALS.APP_SCREEN_NAV_PREV) {
      dispatch(setDirection(GLOBALS.APP_SCREEN_NAV_PREV));
    }
    dispatch(setScreen(GLOBALS.APP_SCREEN_STEP_ONE));
  };
  const handleTryAnother = () => {
    dispatch(setTryAnother());
    dispatch(generateGreeting({ cookies, setCookie, isRetry: true }));
  };
  const handleDownloadAndShare = async () => {
    dispatch(showGreetingShare());
  };
  const handleHideGreetingShare = () => {
    dispatch(hideGreetingShare());
  };
  const downloadGreeting = async (canTrgigger) => {
    try {      
      const dataUrl = await htmlToImage.toPng(grettingPreviewRef.current);
      if (canTrgigger) {        
        setTimeout(() => {
          download(dataUrl, `Greetings-To-${name}.png`, "image/png");
          dispatch(setGreetingDownloaded());
        }, 100)
      }

    } catch (e) {
      console.log(e.message);
    }
  };
  const downloadGreetingWithCacheBust = async (canTrgigger) => {
    try {
      const dataUrl = await htmlToImage.toPng(grettingPreviewRef.current, { cacheBust: true });
      if (canTrgigger) {
        setTimeout(() => {
          download(dataUrl, `Greetings-To-${name}.png`, "image/png");
          dispatch(setGreetingDownloaded());
        }, 100)
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  /*
  const downloadGreeting = async () => {
    try {
      const dataUrl = await htmlToImage.toPng(grettingPreviewRef.current, { cacheBust: true });
      const link = document.createElement("a");
      link.download = `Greetings-To-${name}.png`;
      link.href = dataUrl;
      link.click();
    } catch (e) {
      alert(e.message);
    }
  };
  const downloadGreetingJpg = async () => {
    try {
      const dataUrl = await htmlToImage.toJpeg(grettingPreviewRef.current, { cacheBust: true });
      const link = document.createElement("a");
      link.download = `Greetings-To-${name}.jpeg`;
      link.href = dataUrl;
      link.click();
    } catch (e) {
      alert(e.message);
    }
  };
  */
  useEffect(() => {
    if (shareOverlayShown && !greetingDownloaded) {
      downloadGreeting(false);
      downloadGreetingWithCacheBust(false);
    }
  }, [shareOverlayShown]);

  return (
    <motion.div
      className="absolute inset-0 csa-app-greet"
      custom={direction}
      variants={screenVariants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{ duration: GLOBALS.APP_SCREEN_NAV_DURATION }}
      drag="y"
      dragConstraints={{ top: 0, bottom: 0 }}
      dragMomentum={false}
      dragElastic={0}
      onDragEnd={(e, { offset, velocity }) => {
        const swipe = swipePower(offset.y, velocity.y);
        if (swipe < -swipeConfidenceThreshold) {
          //  showNextScreen();
        } else if (swipe > swipeConfidenceThreshold) {
          showPrevScreen();
        }
      }}
    >
      <div className="absolute inset-0 csa-app-greet-inner">
        <div className="absolute inset-0 csa-app-greet-bg"></div>
        {isGenerating && <GreetingLoader />}
        {!isGenerating && greetText && (
          <GreetingVisual
            key="greet"
            name={name}
            greetText={greetText}
            greetLength={greetLength}
            greetBg={greetBg}
            startOver={handleStartOver}
            tryAnother={handleTryAnother}
            downloadAndShare={handleDownloadAndShare}
          />
        )}
        <AnimatePresence>
          {shareOverlayShown && (
            <GreetingShareOverlay
              name={name}
              greetText={greetText}
              greetLength={greetLength}
              greetBg={greetBg}
              hideGreeting={handleHideGreetingShare}
              downloadGreeting={downloadGreeting}
              downloadGreetingWithCacheBust={downloadGreetingWithCacheBust}
              ref={grettingPreviewRef}
            />
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

const GreetingVisual = ({
  name,
  greetText,
  greetLength,
  greetBg,
  startOver,
  tryAnother,
  downloadAndShare,
}) => {
  return (
    <div className="absolute inset-0">
      <div
        className="absolute top-0 left-0 right-0 w-full"
        style={{ height: "79%" }}
      >
        <div className="relative w-full h-full">
          <div className="relative w-full" style={{ height: "22%" }}>
            <div className="relative w-full h-full flex flex-wrap justify-center items-end">
              <div className="px-20 pt-20 pb-10 text-center">
                <p>
                  Ta-Dah!
                  <br />
                  Here’s your greeting.
                </p>
              </div>
            </div>
          </div>
          <div className="relative w-full" style={{ height: "78%" }}>
            <div className="absolute csa-app-greet-connect-bg">
              <img src={imgRegreetConnect} alt="Regreet" />
            </div>
            <div className="absolute csa-app-greet-stars-group2">
              <img src={imgStarsGroup2} alt="Regreet" />
            </div>
            <div className="absolute csa-app-greet-stars-group3">
              <img src={imgStarsGroup3} alt="Regreet" />
            </div>
            <div className="relative w-full h-full flex flex-wrap justify-center items-center">
              <div className="px-20 pt-10 pb-50 text-center">
                <div className="csa-app-greet-title">Hey {name}!</div>
                {greetLength > GLOBALS.APP_GREET_LENGTH_LIMIT_MAX &&
                  <div className="csa-app-greet-message-sm">{greetText}</div>
                }
                {
                  greetLength <= GLOBALS.APP_GREET_LENGTH_LIMIT_MAX &&
                  <div className="csa-app-greet-message">{greetText}</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute bottom-0 left-0 right-0 w-full"
        style={{ height: "21%" }}
      >
        <div className="absolute inset-0 csa-app-greet-nav"></div>
        <div className="relative w-full h-full flex flex-wrap items-center justify-center">
          <div className="relative w-full">
            <div className="relative px-20 pt-10 pb-10">
              <div className="flex flex-wrap items-center justify-between">
                <button
                  className="cta-link-icon-black"
                  onClick={downloadAndShare}
                >
                  <img className="icon" src={iconShare} alt="Share greeting" />
                  <span className="text">Share greeting</span>
                </button>
                <button className="cta-link-icon-black" onClick={tryAnother}>
                  <img className="icon" src={iconRetry} alt="Try another" />
                  <span className="text">Try another</span>
                </button>
              </div>
            </div>
            <div className="relative pt-10 pb-10 text-center">
              <AppButtonRounded text="START OVER" onClick={startOver} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const GreetingShareOverlay = forwardRef(
  ({ name, greetText, greetLength, greetBg, hideGreeting, downloadGreeting, downloadGreetingWithCacheBust }, ref) => {
    return (
      <motion.div
        variants={overlayGreetingShareVariants}
        initial="enter"
        animate="open"
        exit="closed"
        className="absolute bottom-0 left-0 w-full csa-app-greet-share"
      >
        <div className="w-full h-full">
          <div className="w-full" style={{ height: "70%" }}>
            <div className="relative w-full h-full">
              <div className="csa-app-greet-preview-wrapper">
                <div
                  className={classNames("csa-app-greet-preview", `${greetBg}`)}
                  ref={ref}
                >
                  <div className="relative csa-app-greet-preview-inner">
                    <div className="absolute inset-0 csa-app-greet-preview-content">
                      <div className="absolute csa-app-greet-preview-logo">
                        <img src={imgRegreetLogo} alt="Regreet" />
                      </div>
                      <div className="absolute csa-app-greet-preview-stars-group2">
                        <img src={imgStarsGroup2} alt="Regreet" />
                      </div>
                      <div className="absolute csa-app-greet-preview-stars-group3">
                        <img src={imgStarsGroup3} alt="Regreet" />
                      </div>
                      <div className="relative w-full h-full flex items-center">
                        <div className="relative px-20 pt-20 pb-40">
                          <div className="csa-app-greet-share-title">Hey {name}!</div>
                          {greetLength > GLOBALS.APP_GREET_LENGTH_LIMIT_MAX &&
                            <div className="csa-app-greet-share-message-sm">{greetText}</div>
                          }
                          {
                            greetLength <= GLOBALS.APP_GREET_LENGTH_LIMIT_MAX &&
                            <div className="csa-app-greet-share-message">{greetText}</div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full" style={{ height: "30%" }}>
            <div className="csa-app-greet-share-info">
              <div className="csa-app-greet-share-info-inner">
                <div className="w-full px-20 py-20">
                  <div className="csa-app-greet-share-download">Click the icon to download and</div>
                  <div className="csa-app-greet-social-label">Share your greeting via</div>
                  <div className="csa-app-greet-social-icons">
                    <button className="cta-social-icon" onClick={() => downloadGreeting(true)}>
                      <img src={iconSocialWhatsapp} alt="Whatsapp"></img>
                    </button>
                    <button className="cta-social-icon" onClick={() => downloadGreeting(true)}>
                      <img src={iconSocialFacebook} alt="Facebook"></img>
                    </button>
                    <button className="cta-social-icon" onClick={() => downloadGreeting(true)}>
                      <img src={iconSocialEmail} alt="Email"></img>
                    </button>
                    <button className="cta-social-icon" onClick={() => downloadGreetingWithCacheBust(true)}>
                      <img src={iconSocialPinterest} alt="Pinterest"></img>
                    </button>
                    <button className="cta-social-icon" onClick={() => downloadGreetingWithCacheBust(true)}>
                      <img src={iconSocialMore} alt="More"></img>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="csa-app-greet-share-close" onClick={hideGreeting}>
          <img src={iconClose} alt="Close" />
        </button>
      </motion.div>
    );
  }
);

export default memo(ScreenGreeting);
