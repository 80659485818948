import classNames from "classnames";

const AppButtonLink = ({ text, disabled, onClick }) => {
  return (
    <a
      className={classNames("cta-link-black", { disabled: disabled })}
      onClick={onClick}
    >
      {text}
    </a>
  );
};

export default AppButtonLink;
